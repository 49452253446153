import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "home", loadChildren: () => import("./home/home.module").then((m) => m.HomeModule) },
  { path: "scan-qr", loadChildren: () => import("./scan-qr/scan-qr.module").then((m) => m.ScanQRModule) },
  { path: "register-shop", loadChildren: () => import("./register-shop/register-shop.module").then((m) => m.RegisterShopModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
